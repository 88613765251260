<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">
		<div class="row align-items-center mb-10">

			<h2 class="display-4 mb-0">
				Vente de tokens via Automated Market Maker (AMM)</h2>
		</div>
		<h2>
			Principe de base</h2>
		<p>
			Une personne désireuse de vendre des tokens en utilisant l'unité de compte de l'écosystème Fraction.re (le fcEURO)
			tout en ayant la garantie de vendre immédiatement va émettre un ordre de vente sur la place de marché automatique (AMM).
			<br /><br />
			Le prix unitaire de vente du token n'est pas défini à l'avance puisqu'il sera calculé automatiquement par le marché automatique
			afin de garantir la délivrance des tokens. Un nombre minimum de réception peut toutefois
			être défini afin de ne pas vendre à un prix trop bas.
			<br /><br />
			L'ordre de vente est très simple à soumettre, il suffit d'indiquer le nombre de yoken à vendre et valider.
			Il est aussi possible de définir un montant minimum de réception.


		</p>
		<h2>
			Un exemple concret</h2>
		<p>
			Lise veut vendre 10 Berlcoins et avoir la garantie qu'au moins un 1050 fcEURO lui soit directement crédité sur son compte.
			<br />
		</p>

		<h3 class="mt-12">
			Situation initiale</h3>

		<div class="row ">
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/sell-amm-6.png" />
				<br /> <br /> <b> Le compte de Lise</b><br />
			</div>

		</div>


		<h3 class="mt-12">
			Ordre de vente de 10 Berlsoin pour un minimum de 1050 fcEURO </h3>


		<p>Dans la section <b>Vente</b>.</p>


		<div class="row ">
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/sell-amm-7.png" /><br />
				<br /> <br />
				<b>Lise sélectionne le menu Achat et arrive sur la liste des tokens disponibles s'affiche
				</b> <br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/sell-amm-8.png" /><br />
				<br /> <br />
				<b>Lise sélectionne le token Berlcoin, le menu des ordres s'affiche, elle clique sur VENTE DIRECTE (AMM)
				</b> <br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/sell-amm-9.png" /><br />
				<br /> <br />
				<b>Lise indique le montant de la transaction et le nombre minimum de fcEURO à recevoir et valide.
				</b> <br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/sell-amm-10.png" /><br />
				<br /> <br />
				<b>Lise a reçu 1138.40 fcEURO, car l'AMM lui à permis de trouver des Berlcoin à 113.84 fcEURO.
				</b> <br />
			</div>
		</div>


		<div class="row ">
			<h3 class="mt-12">
				Coût de l'opération</h3>
			<p> Lise a payé 0.00001 XLM (ici à 0.20 euro pour 1 XLM ) pour l'ordre de vente
				<br /> <br /> Au final moins de 1 centime d'euro.
			</p>
		</div>
	</div>

</section>
</template>


<script>
export default {
	name: 'account',
	components: {},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
